export const settlement = {
    common: {
        floor: 'Этаж',
    },
    realty_types: {
        undefined: "Неопределенный",
        apartment: "Квартира",
        parking_space: "Машиноместо",
        storage: "Кладовая",
        non_residential: "Нежилое", 
    },
    acceptance_status: {
        get_keys: 'Получить ключи',
        information: 'Информация',
    },
    acceptance_status_manager: {
        undefined: "Неизвестный",
        invited: "Приглашен",
        new: "Новая",
        appointmented: "Записан",
        prepared: "Подготовлен",
        successResult: "Завершена без замечаний",
        resultWithRemarks: "Завершена с замечаниями",
        failResult: "Завершена без АПП",
        cancelled: "Отменена",
    },
    client: {
        list: {
            empty: 'Список объектов пока пуст',
            emptyDescription: 'После покупки здесь появятся все ваши объекты',
            toRealtyCatalog: 'Перейти в каталог'
        },
        appointment: {
            title: 'Запись на приемку',
            text: 'Укажите, кто будет присутствовать на приёмке в период',
            info: {
                title: 'Информация для собственника',
                text: 'Если объект недвижимости приобретался в долевую собственность (несколько участников), то при его приёмке должны присутствовать все участники долевого строительства, указанные в договоре.',
            },
            presence: {
                yourself: 'Самостоятельно',
                representative: 'Представитель по нотариальной доверенности',
            },
            validation: {
                agreement: 'Ознакомьтесь с информацией'
            }

        },
        personal_data: {
            title: 'Проверьте ваши данные',
            text: 'Если данные неверны, вы можете отредактировать их, либо поставить отметку для менеджера, что данные неактуальны.',
            form: {
                basic: 'Основные',
                passport: 'Паспорт',
                lastName:'Фамилия',
                firstName:'Имя',
                patronymicName: 'Отчество', 
                birthDate:'Дата рождения',
                placeOfBirth:'Место рождения',
                email: 'Email',
                passportSeries: 'Серия и номер',
                passportDate: 'Дата выдачи',
                passportCode: 'Код подразделения',
                passportIssued: 'Кем выдан',
                registrationAddress: 'Адрес по месту прописки',
                residenceAddress: 'Адрес фактического проживания',
                isAddressMatch: 'Адрес фактического проживания совпадает с адресом прописки',
            },
            placeholders: {
                lastName: 'Введите фамилию',
                firstName: 'Введите имя',
                patronymicName: 'Введите отчество', 
                birthDate: 'Введите дату рождения',
                placeOfBirth: 'Введите место рождения',
                email: 'Введите email',
                passportSeries: 'Введите серию паспорта',
                passportNumber: 'Введите номер паспорта',
                passportDate: 'Введите дату выдачи',
                passportCode: 'Введите код подразделения',
                passportIssued: 'Введите название учреждения',
                registrationAddress: 'Введите адрес по месту прописки',
                residenceAddress: 'Введите адрес проживания',
            },
        },
        date: {
            title: 'Выберите дату и время приемки',
            text1: 'Приёмка по вашему объекту будет проходить в период',
            text2: 'Выберите удобное вам время в этом промежутке дат.',
            dialog: {
                title: 'Приемка назначена',
                text: 'Встреча пройдет',
            },
        },
        scheduled: {
            title: 'Приёмка назначена',
            date: 'Дата приемки', 
            cancel: 'Отменить приемку',
            info: {
                title: 'Приходите за 10 минут до встречи c паспортом',
                text: 'На приёмке присутствуют все участники договора. Акт подписывает сам участник или его доверенное лицо по нотариальной доверенности.',
            },
            dialog_cancel: {
                title: 'Отмена приемки',
                placeholder: 'Укажите причину отмены приемки',
            },
            dialog_success: {
                title: 'Приёмка отменена',
                text: 'Вы можете записаться на другую доступную дату и время.',
            },
        },
        completed: {
            remarks: 'Выявленные замечания',
            acceptance: {
                success: {
                    title: 'Приемка завершена',
                    alert: {
                        title: 'Поздравляем',
                        text: 'Объект успешно принят'
                    }
                },
                remarks: {
                    title: 'Приемка завершена с замечаниями',
                    alert: {
                        title: 'Приемка завершена с замечаниями',
                        text: 'По замечаниям создана техническая заявка и передана специалисту. Когда работы будут завершены мы повторно пригласим вас на осмотр.'
                    }
                },
                withoutSign: {
                    title: 'Завершено без подписи акта приема-передачи',
                    alert: {
                        title: 'Заверешно без подписи акта приема-передачи',
                        text: 'По замечаниям создана техническая заявка и передана специалисту. Когда работы будут завершены мы повторно пригласим вас на осмотр.'
                    }
                }
            }
        },
    },
    admin: {
        list: {
            title: "Объекты недвижимости",
            tabs: {
                realty: "Объекты",
                houses: "Дома"
            },
            house: {
                title: "Дом",
                acceptanceDays: "Дни приемки"
            },
            excel : {
                import: "Импорт",
                importStarted: "Начался импорт файла"
            },
            acceptance: {
                sended: "Приглашения на приёмку разосланы"
            },
            select: {
                sendMessage: 'Отправить сообщение',
                objects: 'объекта/-ов',
                resetSelect: 'Сбросить выделение'
            },
            table: {
                filter: {
                    placholder: {
                        house: 'Дом',
                        status: 'Статус',
                        realtyType: 'Тип',
                        realtyNumber: '№ БТИ',
                        user: 'Пользователь'
                    }
                },
                acceptance: {
                    sended: "Приглашения на участие в конкурсе уже разосланы."
                },
                columns: {
                    object: "Объект",
                    status: "Статус",
                    owner: "Владелец",
                    phoneNumber: "Номер телефона"
                }
            }
        },
        monitoring: {
            setSchedule: 'Задать расписание',
            planningTime: 'Планирование рабочего времени',
            workingDays: 'Рабочие дни',
            managers: 'Менеджеры',
            timeSlotDescription: 'Создадим доступные временные слоты для менеджеров на указанные даты и время, чтобы клиенты могли записаться для получения ключей.',
            scheduleSaved: 'Расписание задано'
        },
        acceptances: {
            title: 'Записи на приемку',
            placeholders: {
                house: 'Все дома',
                status: 'Статус',
                type: 'Тип',
                object_number: 'Номер объекта',
                client: 'Клиент',
                date: 'Даты',
                manager: 'Выберите менеджера',
                accepter: 'Введите ФИО приемщика',
                comment: 'Введите технический комментарий',
                remarks: 'Введите замечания',
            },
            table: {
                address: 'Адрес',
                section: 'Секция',
                number: 'Номер',
                date: 'Дата и время',
                status: 'Статус',
                fullNameManager: 'Менеджер',
            },
            realty_info: {
                realtyTypeName: "Тип комнат",
                square: "Общая площадь",
                floorNumber: "Этаж",
                finishing: "Отделка",
                projectName: "Проект",
                sectionNumber: "Секция",
            },
            dialog_titles: {
                deal: 'Сделка',
                date: 'Дата и время визита',
                manager: 'Менеджер',
                accepter: 'Приемщик',
                owners: 'Собственники',
                comment: 'Технический комментарий',
                remarks: 'Замечания',
            },
            dialog_owner: {
                passportSeries: 'Серия и номер',
                passportIssued: 'Кем выдан',
                passportDate: 'Дата выдачи',
                passportCode: 'Код подразделения',
                registrationAddress: 'Адрес по месту прописки',
            },
            errors: {
                status: "Статус 'Приглашен' нельзя выбрать",
            },
        },
    }
}